import React from "react";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import "../styles/_app.css";

// Components
import Hero from "../components/Hero";
import LogoGrid from "../components/LogoGrid";
import FullwidthImage from "../components/FullwidthImage";
import TeamMemberGrid from "../components/TeamMemberGrid";
import TextBlock from "../components/TextBlock";
import BlogCardGrid from "../components/BlogCardGrid";
import CtaCard from "../components/CtaCard";
import ImageSlider from "../components/ImageSlider";
import VideoPlayer from "../components/VideoPlayer";
import WorkCard from "../components/WorkCard";

export default function Components({ children }) {
  return (
    <Layout heroVariation="FullScreen">
      <SEO />

      <Hero
        heroButtonType="primary"
        heroVariation="FullScreen"
        heroBackgroundVideo={[{ url: "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4" }]}
      />

      <Hero heroVariation="Default" heroButtonType="primary" />

      <div className="title-lead">
        <div className="container container--1132px">
          <p><b>Page Title Component</b></p>
          <h1 className="mb0">
            This is a really really really really really long page title
            component.
          </h1>
        </div>
      </div>

      <div className="container container--1132px">
        <p><b>Blog Article Title Component</b></p>
      </div>

      <div className="title-lead title-lead--blog">
        <div className="container container--1132px mb0" data-color="Blue">
          <h1 className="mb0">
            Beginner's Guide To Calculating Trade Show ROI
          </h1>
          <footer>
            <time dateTime="1574451660000">Nov 22, 2019</time>
            <div className="author-info">
              <img
                src="https://hamiltonexhibits.s3.us-east-2.amazonaws.com/uploads/users/HME_Mark.svg"
                alt=""
              />
              <b>Hamilton Exhibits</b>
            </div>
          </footer>
        </div>
      </div>

      <div className="container container--1132px">
        <p><b>Global Typography</b></p>

        <h1>Heading 1</h1>
        <h2>Heading 2</h2>
        <h3>Heading 3</h3>
        <h4>Heading 4</h4>
        <h5>Heading 5</h5>
        <h6>Heading 6</h6>
        <p>
          Lorem ipsum dolor sit amet <a href="http://google.com">consectetur</a>{" "}
          adipisicing elit. Optio eveniet, ipsum laboriosam quis totam facilis
          quia deserunt repellendus assumenda voluptate. Dolores maiores,
          repellendus nobis ducimus blanditiis nostrum reiciendis accusamus
          earum?
        </p>

        <ul>
          <li>Item 1</li>
          <li>Item 2</li>
          <li>Item 3</li>
          <li>Item 4</li>
        </ul>
      </div>

      <div className="container container--1132px">
        <p><b>FullwidthImage Component</b></p>
      </div>
      <FullwidthImage image={[{
        url: '',
        xLarge: 'https://via.placeholder.com/1600x500',
        large: 'https://via.placeholder.com/1024x500',
        medium: 'https://via.placeholder.com/700x500',
        small: 'https://via.placeholder.com/500x500',
        xLarge_2x: 'https://via.placeholder.com/3200x1000',
        large_2x:'https://via.placeholder.com/2052x1000',
        medium_2x: 'https://via.placeholder.com/1400x1000',
        small_2x: 'https://via.placeholder.com/1000x1000'
       }]} />

      <div className="container container--1132px">
        <p><b>TextBlock Component</b></p>
      </div>
      <TextBlock
        richText={`<h2>Heading Level 2</h2><ul><li>Item 1</li><li>Item 2</li><li>Item 3</li><li>Item 4</li></ul><p>Develop, design, fabricate, and <a href="http://google.com">activate</a> an experiential trade show booth that communicates Mailchimp is an all-in-one marketing platform that empowers small business in real life and connects with attendees through a measurable interactive engagement online following the event.</p><h3>Heading Level 3</h3><p>Donec id elit non mi porta gravida at eget metus. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec sed odio dui. Sed posuere consectetur est at lobortis. Cras mattis consectetur purus sit amet fermentum.</p><h3>Heading Level 4</h3><p>Donec id elit non mi porta gravida at eget metus. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec sed odio dui. Sed posuere consectetur est at lobortis. Cras mattis consectetur purus sit amet fermentum.</p>`}
      />

      <div className="container container--1132px">
        <p><b>LogoGrid Component</b></p>
      </div>
      <LogoGrid />

      <div className="container container--1132px">
        <p><b>WorkCard Component</b></p>
      </div>
      <WorkCard />

      <div className="container container--1132px">
        <p><b>VideoPlayer Component</b></p>
        <VideoPlayer
          type="embed"
          ratio="16:9"
          src="https://youtu.be/guEn4MkE0sw"
        />
      </div>

      <div className="container container--1132px">
        <p><b>ImageSlider Component</b></p>
      </div>
      <ImageSlider
        images={[
          {
            crop: "https://via.placeholder.com/500",
            crop_2x: "https://via.placeholder.com/500"
          },
          {
            crop: "https://via.placeholder.com/500",
            crop_2x: "https://via.placeholder.com/500"
          },
          {
            crop: "https://via.placeholder.com/500",
            crop_2x: "https://via.placeholder.com/500"
          },
          {
            crop: "https://via.placeholder.com/500",
            crop_2x: "https://via.placeholder.com/500"
          },
          {
            crop: "https://via.placeholder.com/500",
            crop_2x: "https://via.placeholder.com/500"
          },
          {
            crop: "https://via.placeholder.com/500",
            crop_2x: "https://via.placeholder.com/500"
          }
        ]}
      />

      <div className="container container--1132px">
        <p><b>TeamMemberGrid Component</b></p>
      </div>
      <TeamMemberGrid />

      <div className="container container--1132px">
        <p><b>BlogCardGrid Component</b></p>
      </div>
      <BlogCardGrid />

      <div className="container container--1132px">
        <p><b>CtaCard Component (2 variations: overlay or w/out overlay)</b></p>
      </div>
      <CtaCard overlay={true} />
      <CtaCard />
    </Layout>
  );
}
